@import "colors";

.leaflet-dragging .sticker-container {
  transition: none !important;
}

.sticker-container {
  outline: none;
  position: relative;
  transition: transform 250ms;
  cursor: pointer;
  z-index: 10;

  &.leaflet-drag-target {
    transition: none !important;
  }

  &:before {
    content: ' ';
    background: $red_secondary;
    width: 48px;
    height: 48px;
    left: -24px;
    top: -24px;
    position: absolute;
    border-radius: 40px;
    opacity: 0.25;
    transform: scale(0.5);
    transition: opacity 250ms, transform 500ms;
  }

  &:active {
    transition: transform 250ms;

    .sticker-delete {
      pointer-events: none;
    }
  }

  &:hover, &:active {
    &:before {
      opacity: 0.3;
      transform: scale(1);
    }
  }

  &.inactive {
    pointer-events: none;
    transition: transform 250ms;

    .sticker-delete {
      display: none;
    }

    .sticker-desc.is_empty {
      display: none;
    }
  }

  @media (hover: hover) {
    transition: none;
  }
}

.sticker-label {
  width: 48px;
  height: 48px;
  position: absolute;
  //background: white;
  //border-radius: 32px;
  left: 0;
  top: 0;
  outline: none;

  //&:after {
  //  content: ' ';
  //  box-shadow: 0 0 0 1px #ff3344;
  //  width: 80px;
  //  height: 80px;
  //  left: -16px;
  //  top: -16px;
  //  position: absolute;
  //  border-radius: 40px;
  //  pointer-events: none;
  //  opacity: 0;
  //}

  svg {
    left: -8px;
    top: -8px;
    position: relative;
    z-index: 0;
  }

  .sticker-image {
    width: 72px;
    height: 72px;
    left: -12px;
    top: -12px;
    position: relative;
    z-index: 0;
    background-size: cover;
  }

  &.left {
    .sticker-desc {
      padding: 10px 36px 10px 10px;
      left: auto;
      right: 24px;
    }
  }
}

.sticker-wrapper {
  will-change: transform;
  transition: transform 250ms;

  @media (hover: hover) {
    transition: transform 0ms;
  }
}

.sticker-arrow {
  position: absolute;
  transform-origin: 0 0;
  left: 0;
  top: 0;
  pointer-events: none;

  &:after {
    content: ' ';
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='38' height='38' viewBox='0 0 38 38' preserveAspectRatio='none'%3E%3Cpolygon points='0,0 38,20 38,38 20,38' style='fill:%23ff3344;' /%3E%3C/svg%3E") no-repeat;
    width: 50px;
    height: 50px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    left: 0;
    top: 0;
    position: absolute;
  }
}

.sticker-delete {
  position: absolute;
  width: 24px;
  height: 24px;
  background: red;
  border-radius: 24px;
  transition: transform 500ms;
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
  left: 28px;
  top: -16px;
  z-index: 20;

  &:hover {
    transform: scale(1.2) !important;
  }

  &::before, &::after {
    content: ' ';
    position: absolute;
    left: 11px;
    top: 6px;
    width: 2px;
    height: 12px;
    background: white;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.stickers-helper {
  flex-direction: column;
  padding-top: 0;
  max-height: calc(100vh - 60px - 72px);
  overflow: auto;
}

.stickers-grid {
  // display: grid;
  // grid-template-columns: repeat( auto-fit , minmax(48px, 1fr) );
  display: flex;
  flex-wrap: wrap;
}

.stickers-set-title {
  opacity: 0.3;
  font-size: 0.8em;
  padding: 10px 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &::after {
    content: ' ';
    height: 1px;
    flex: 1;
    background-color: #666666;
    margin-left: 10px;
  }
}

.sticker-preview {
  float: left;
  cursor: pointer;
  transform: scale(1);
  transition: transform 250ms;
  width: 48px;
  height: 48px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 50%;
  position: relative;

  &:hover {
    transform: scale(1.5);
    z-index: 2;
  }
}

.sticker-desc {
  min-width: 60px;
  z-index: -1;
  height: auto;
  background: #222222;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translate3d(0, -50%, 0);
  color: white;
  box-sizing: border-box;
  padding: 10px 10px 10px 36px;
}

.sticker-desc-sizer {
  position: relative;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  padding: 1px;

  textarea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font: inherit;
    padding: 0;
    border: none;
    background: none;
    color: white;
    resize: none;
    outline: none;
    overflow: hidden;
  }
}

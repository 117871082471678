@import "colors";

.button {
  background: #444444;
  padding: 4px 16px;
  height: 18px;
  line-height: 1em;
  border-radius: $button_radius;
  font-family: inherit;
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  box-shadow: inset rgba(100, 100, 100, 0.3) 1px 0, inset rgba(0,0,0, 0.1) -1px 0;
  color: white;
  font-weight: 400;
  border: none;
  position: relative;

  &.outline {
    box-shadow: inset #444444 0 0 0 1px;
    background: transparent;
  }

  &.primary {
    background: #3c78db;
    color: white;
  }

  &.danger {
    background: #ed2f3b;
    color: white;
  }

  &.success {
    background: #17bf6d;
    color: white;
  }

  &.disabled {
    opacity: 0.5;
    touch-action: none;
    pointer-events: none;
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.button-group {
  display: flex;

  .button {
    border-radius: 0;
    margin: 0;

    &:first-child {
      border-radius: $button_radius 0 0 $button_radius;
    }

    &:last-child {
      border-radius: 0 $button_radius $button_radius 0;
    }
  }
}

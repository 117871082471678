@import "colors";

.user-bar {
  // width: 160px;

  .button {
    width: 100%;
  }
}

.user-bar-guest {
   width: 38px;
  @media (min-width: $mobile_breakpoint) {
    width: 158px;
  }
}

.user-button {
  width: 120px;
  padding-left: 48px;
  height: 48px;
  display: flex;

  user-select: none;
  cursor: pointer;

  @media (max-width: $mobile_breakpoint) {
    width: 0;

    .user-button-fields {
      display: none;
    }
  }
}

.user-button-picture {
  width: 48px;
  height: 48px;
  background-size: cover;
  background: rgba(0, 0, 0, 0.3) 50% 50%;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
}

.user-button-fields {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 0 10px;
  overflow: hidden;
  box-sizing: border-box;

  div {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.user-button-name {
  font-size: 14px;
}

.user-button-text {
  font-size: 12px;
  opacity: 0.5;
  padding-top: 2px;
  font-weight: 500;
}


.user-panel {
  position: relative;
}

.user-panel-menu {
  position: absolute;
  bottom: 58px;
  left: 0;
  width: 219px;
  background: $dialog_background;
  border-radius: $panel_radius;
  padding: 0;
  box-sizing: border-box;
  z-index: -1;

  @media (max-width: $mobile_breakpoint) {
    left: 0;
    z-index: 4;
  }
}

.user-panel-title {
  font-size: 20px;
  padding: 10px;

  background: rgba(0, 0, 0, 0.5);
  box-shadow: inset rgba(255, 255, 255, 0.1) 0 -1px;
  border-radius: $panel_radius $panel_radius 0 0;
  user-select: none;
}

.user-panel-ver {
  font-size: 12px;
  vertical-align: text-top;
  opacity: 0.5;
  padding-left: 3px;
  position: relative;
  top: 3px;
}

.user-panel-item {
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  width: 100%;
  transition: all 250ms;
  color: white;
  box-sizing: border-box;
  user-select: none;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-decoration: none;

  &:first-child::after {
    border-radius: 3px 3px 0 0;
  }

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(150deg, $blue_primary, $blue_secondary);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: -1;
  }

  &:hover::after {
    opacity: 0.5;
  }
}

.user-panel-text {
  padding: 10px;
  opacity: 0.5;
  font-weight: 200;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  div {
    padding-top: 5px;
  }
}

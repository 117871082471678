$blue_primary: #55ddff;
$blue_secondary: #7137c8;

$router_line: #4597d0;

$bar_background: #333333;
$dialog_background: #271535;

$location_line: #ff3344;

$green_primary: #abc837;
$green_secondary: #009c80;

$red_primary: #ff7034;
$red_secondary: #ff3344;

$panel_radius: 8px;
$button_radius: 6px;

$color_primary: #4597d0;
$color_success: #7cd766;
$color_danger: #ff3344;

$bar_shadow: rgba(0,0,0,0.3) 0 2px 0, inset rgba(255, 255, 255, 0.05) 1px 1px;
$dialog_shadow: rgba(0,0,0,0.3) 0 2px 0;

$mobile_breakpoint: 768px;
$micro_breakpoint: 450px;

$tooltip_background: #123740;

$loading_shade: darken($blue_secondary, 20%);
$cluster_small: #0069a7;

$title_dialog_color: darken(#111111, 85%);

@import "colors";

$disabledColor: #ccc;
$border-radius-base: 6px;
$primary-color: transparent;
$tooltip-color: #fff;
$tooltip-bg: darken(#666, 4%);
$tooltip-arrow-width: 4px;
$tooltip-distance: $tooltip-arrow-width+4;
$tooltip-arrow-color: $tooltip-bg;
$ease-out-quint      : cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint       : cubic-bezier(0.755, 0.05, 0.855, 0.06);

@mixin borderBox() {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //  remove tap highlight color for mobile safari

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //  remove tap highlight color for mobile safari
  }
}

.rc-slider {
  position: relative;
  height: 40px;
  padding: 15px 0;
  // width: 100%;
  margin: 8px;
  border-radius: $border-radius-base;
  touch-action: none;

  @include borderBox();

  &-rail {
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    height: 4px;
    border-radius: $border-radius-base;
    pointer-events: none;
  }

  &-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: $border-radius-base;
    background: linear-gradient(270deg, $red_secondary, $blue_secondary);
    // background-size: 320px 100px;
  }

  &-handle {
    position: absolute;
    margin-left: -7px;
    margin-top: -5px;
    width: 14px;
    height: 14px;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px darken($primary-color, 50%);
    background-color: #fff;
    touch-action: pan-x;

    &::after {
      content: '';
      display: block;
      width: 40px;
      height: 50px;
      position: absolute;
      left: -15px;
      top: -16px;
      border-radius: 4px;
      background: black;
      opacity: 0.2;
    }

    &:focus {
      // border-color: darken($primary-color, 20%);
      box-shadow: 0 0 0 4px darken($primary-color, 50%);
      outline: none;
    }

    &-click-focused:focus {
      // border-color: darken($primary-color, 50%);
      box-shadow: unset;
    }

    &:hover {
      border-color: darken($primary-color, 20%);
    }

    &:active {
      border-color: darken($primary-color, 20%);
      box-shadow: 0 0 5px darken($primary-color, 20%);
      cursor: grabbing;
    }
  }

  &-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
    pointer-events: none;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.3);
    padding-top: 10px;
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  &-dot {
    position: absolute;
    bottom: -2;
    margin-left: -0.5px;
    width: 1px;
    height: 12px;
    // border: 2px solid #e9e9e9;
    background-color: black;
    opacity: 0.2;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;

    &-active {
      // border-color: darken($primary-color, 50%);
      background-color: white;
      opacity: 0.2;
    }
  }

  &-disabled {
    .rc-slider-track {
      background-color: $disabledColor;
    }

    .rc-slider-handle, .rc-slider-dot {
      border-color: $disabledColor;
      box-shadow: none;
      background-color: #fff;
      cursor: not-allowed;
      opacity: 0;
    }

    .rc-slider-mark-text, .rc-slider-dot, .rc-slider-track {
      cursor: not-allowed !important;
      opacity: 0;
    }
  }
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;

  .rc-slider {
    &-rail {
      height: 100%;
      width: 4px;
    }

    &-track {
      left: 5px;
      bottom: 0;
      width: 4px;
    }

    &-handle {
      margin-left: -5px;
      margin-bottom: -7px;
      touch-action: pan-y;

      position: relative;
    }

    &-mark {
      top: 0;
      left: 18px;
      height: 100%;
    }

    &-step {
      height: 100%;
      width: 4px;
    }

    &-dot {
      left: 2px;
      margin-bottom: -4px;

      &:first-child {
        margin-bottom: -4px;
      }

      &:last-child {
        margin-bottom: -4px;
      }
    }
  }
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;

  @include borderBox();

  &-hidden {
    display: none;
  }

  &-placement-top {
    padding: $tooltip-arrow-width 0 $tooltip-distance 0;
  }

  &-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: $tooltip-color;
    text-align: center;
    text-decoration: none;
    background-color: $tooltip-bg;
    border-radius: $border-radius-base;
    box-shadow: 0 0 4px #d9d9d9;
  }

  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &-placement-top &-arrow {
    bottom: $tooltip-distance - $tooltip-arrow-width;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
}

.range-placeholder {
  height: 40px;
  padding: 15px 0;
  box-sizing: border-box;
  margin: 8px;

  &::after {
    content: ' ';
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    height: 4px;
    border-radius: 6px;
    pointer-events: none;
  }
}

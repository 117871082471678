@import "colors";

.switch {
  height: 1em;
  width: 2em;
  border-radius: 0.5em;
  box-shadow: inset white 0 0 0 0.1em;
  display: inline-flex;
  vertical-align: text-top;
  position: relative;
  top: 0.05em;
  transition: all 500ms;

  &::after {
    content: ' ';
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    width: 0.6em;
    height: 0.6em;
    border-radius: 0.3em;
    background: white;
    transition: all 500ms;
  }

  &.active {
    background: white;

    &::after {
      left: 1.2em;
      background: #333333;
    }
  }
}

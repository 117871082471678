@import "colors";

.save-helper {
  padding: 0;
  flex-direction: column;
}

.save-title {
  padding: 10px;
  width: 100%;
  background: linear-gradient(175deg, #abc837 -50%, #009c80 150%);
  flex-direction: column;
  border-radius: $panel_radius $panel_radius 0 0;
  font-weight: 200;
  box-sizing: border-box;
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, -20px, 0) scale(1);
  }
}

.save-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $loading_shade;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 250ms;
  opacity: 0;
  touch-action: none;
  pointer-events: none;
  text-transform: uppercase;
  font-size: 1.2em;
  color: darken(white, 70%);

  svg {
    fill: white;

    &:nth-child(2) {
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      animation-delay: 400ms;
    }
  }

  &.active {
    opacity: 1;
    touch-action: auto;
    pointer-events: all;

    svg {
      animation: jump infinite alternate 600ms;
    }
  }
}

.save-description {
  padding: 5px 10px;
}

.save-title-input {
  background: rgba(0, 0, 0, 0.3);
  border-radius: $panel_radius;
  display: flex;

  input {
    width: 100%;
    padding: 5px;
    background: transparent;
    border: none;
    outline: none;
    color: white;

    font-family: inherit;
    font-size: 14px;
    font-weight: 200;
  }
}

.save-title-label {
  display: flex;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
}

.save-address-input {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: flex;
  margin-bottom: 5px;

  input {
    padding: 5px 5px 5px 2px;
    background: transparent;
  }
}

.save-address-label {
  display: flex;
  padding: 5px 0 5px 10px;
  height: 100%;
  opacity: 0.5;
  white-space: nowrap;
}

.save-address-copy {
  background: rgba(255, 255, 255, 0.1);
  padding: 0 5px;
  border-radius: 0 $panel_radius $panel_radius 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  svg {
    fill: white;
  }
}

.save-text {
  padding: 10px;
  line-height: 1.1em;
  min-height: 2.2em;
  font-size: 0.9em;
}

.save-buttons {
  display: flex;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 5px;

  .button {
    margin-left: 10px;
  }
}

.save-buttons-text {
  flex: 1;
  padding: 0 10px 2px 10px;
  align-items: center;
  display: flex;

  .switch {
    margin-right: 0.5em;
  }
}

.save-description {
  textarea {
    background: rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
    border-radius: 3px;
    width: 100%;
    resize: none;
    color: inherit;
    font: inherit;
    padding: 5px 10px;
    font-size: 14px;
  }
}

.save-controls {
  padding: 5px 10px;
  user-select: none;
}

@import "colors";

.renderer-shade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  box-sizing: border-box;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: opacity 250ms;
  }

  img#rendererOutput {
    width: 300px;
    height: 300px;
  }

  .croppr-region {
    box-shadow: rgba(255, 255, 255, 0.2) 0 0 0 6px, rgba(0, 0, 0, 0.3) 0 0 0 1px;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .croppr-handle {
    width: 12px;
    height: 12px;
    border-radius: 8px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 0 1px;
  }
}

.renderer-logo {
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;

  transform-origin: 100% 100%;
}

@import "colors";

.router-waypoint {
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  outline: none;
  z-index: 10001;
  position: absolute;

  &:after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    box-shadow: 0 0 0 2px $router_line;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  &:before {
    content: ' ';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 10px;
    box-shadow: 0 0 0 2px $router_line;
    background: $router_line;
    position: absolute;
    left: 18px;
    top: 18px;
  }

}

.router-helper {
}

.router-helper__button {
  white-space: nowrap;
}
